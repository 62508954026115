





















































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { DateTime, Duration } from 'luxon'
import BookingDialog from '@/components/booking/BookingDialog.vue'
import Booking from '@/models/booking/Booking'
import { Watch } from 'vue-property-decorator'
import BookingService from '@/models/booking/BookingService'
import BookingResource from '@/models/booking/BookingResource'
import BookingDraft from '@/models/booking/BookingDraft'
import BookingPlace from '@/models/booking/BookingPlace'

class CalendarEvent {
  public name: string
  public start: Date // Date obj w/timezone (as displayed in calendar)
  public end: Date // Date obj w/timezone (as displayed in calendar)
  public timed: boolean
  public category: string
  public dirty: boolean
  public booking: Booking // Booking.time is UTC string (as used by backend)
  public didMove: boolean
  public wasCreatedNow: boolean
  public draft: BookingDraft
  public newId: string

  public constructor(data: Record<string, unknown> = null) {
    if (!data) {
      data = {}
    }
    this.name = data.name as string
    this.start = data.start as Date
    this.end = data.end as Date
    this.timed = !!data.timed
    this.dirty = !!data.dirty
    this.category = data.category as string
    this.didMove = false // if start or end time was moved in the LAST drag-n-drop operation
    this.wasCreatedNow = !!data.wasCreatedNow // if the LAST drag-n-drop operation created it
    this.booking = null
    this.draft = null
    this.newId = (data.newId as string) || ''
  }

  public getUniqueId(): string {
    return this.booking?.id ? 'existing-' + this.booking.id : this.newId
  }

  public update(data: CalendarEvent) {
    this.name = data.name as string
    this.start = data.start as Date
    this.end = data.end as Date
    this.category = data.category as string
  }

  public get color(): string {
    if (!this.booking) {
      return 'orange'
    }
    return this.dirty ? 'cyan' : 'blue'
  }

  public onDragEnd(): void {
    this.checkDirty()
  }

  public getStartTimeAsString(): string {
    return DateTime.fromJSDate(this.start).toUTC().toFormat('yyyy-MM-dd HH:mm:ss').replace(' ', 'T')
  }

  public getDuration(): number {
    const start = DateTime.fromJSDate(this.start)
    const end = DateTime.fromJSDate(this.end)
    const msec = end.diff(start).milliseconds
    return Math.round(msec / 1000 / 60)
  }

  public checkDirty() {
    if (this.booking) {
      const original = bookingToEvent(this.booking)
      if (original.start?.getTime() !== this.start?.getTime() || original.end?.getTime() !== this.end?.getTime()) {
        this.dirty = true
      } else if (original.category !== this.category) {
        this.dirty = true
      } else {
        this.dirty = false
      }
    } else {
      this.dirty = true
    }
  }
}

const bookingToEvent = (b: Booking): CalendarEvent => {
  const start = b.time ? DateTime.fromISO(b.time as string, { zone: 'UTC' }) : null
  const end = start && b.duration ? start.plus(Duration.fromISO('PT' + b.duration + 'M')) : null
  const ev = new CalendarEvent()
  if (b.carLicenseplate) {
    ev.name = b.carLicenseplate as string
  } else if (b.contactName) {
    const names = (b.contactName as string).split(' ')
    ev.name = names[0]
  } else {
    ev.name = '(Unknown)'
  }
  ev.start = start ? start.toJSDate() : null
  ev.end = end ? end.toJSDate() : null
  ev.timed = true
  ev.category = b.work && b.work[0] ? (b.work[0].resource.name as string) : ''
  ev.dirty = false
  ev.booking = b
  return ev
}

const draftToEvent = (d: BookingDraft): CalendarEvent => {
  const start = DateTime.fromISO(d.time as string, { zone: 'UTC' })
  const end = start.plus(Duration.fromISO('PT' + d.duration + 'M'))
  const ev = new CalendarEvent()
  if (d.carLicenseplate) {
    ev.name = d.carLicenseplate as string
  } else if (d.contactName) {
    const names = (d.contactName as string).split(' ')
    ev.name = names[0]
  } else {
    ev.name = '(Unknown)'
  }
  ev.start = start.toJSDate()
  ev.end = end.toJSDate()
  ev.timed = true
  ev.category = d.resource?.name || ''
  ev.dirty = false
  return ev
}

@Component({
  components: {
    BookingDialog,
  },
})
export default class Calendar extends Vue {
  private TypeMonth = 'month'
  private TypeWeek = 'week'
  private TypeDay = 'category'

  private showWeekends = false
  private type = 'week'
  private types = []
  private startOfDayTime = ''
  private endOfDayTime = ''
  private intervalMinutes = 0
  private startOfDayTimeInput = ''
  private endOfDayTimeInput = ''
  private intervalMinutesInput = 0
  private areDayTimesAutomatic = false
  private startOfDayTimeFromEvents = ''
  private endOfDayTimeFromEvents = ''

  private locale = null
  private now: DateTime = null
  private nowInterval = null
  private calendarId = 0
  private defaultPlaceId: number = null
  private defaultResourceId: number = null
  private defaultServiceId: number = null
  private isReady = false
  private isLoading = false
  private isSaving = false
  private focus = ''
  private events = []
  private resources = []
  private places = []
  private services = []
  private activeBookingDialogVisible = false
  private activeBookingDialogKey = 1
  private activeBooking = null
  private activeBookingDraft = null
  private activeEvent = null

  private dndDragEvent: CalendarEvent = null
  private dndCreateEvent: CalendarEvent = null
  private dndDragTime = null
  private dndCreateStart = null
  private dndExtendOriginal = null

  private newBookingReferenceType = ''
  private newBookingReferenceId = ''

  private stashedChanges = []

  // ===========================================================================
  // Init
  // ===========================================================================

  public created(): void {
    this.types.push({ id: this.TypeMonth, label: this.$t('c:calendar:Month') })
    this.types.push({ id: this.TypeWeek, label: this.$t('c:calendar:Week') })
    this.types.push({ id: this.TypeDay, label: this.$t('c:calendar:Day') })
  }

  private mounted(): void {
    if (this.$route.query.newBookingType && this.$route.query.newBookingId) {
      this.newBookingReferenceType = this.$route.query.newBookingType as string
      this.newBookingReferenceId = this.$route.query.newBookingId as string
    }
    this.onPreparedToInitialize()
  }

  // Await store's calendar, and this component's mount, and when everything is ready, start initializing
  @Watch('defaultCalendarId')
  private onPreparedToInitialize() {
    // Only initialize when we have store's calendars, and has not yet initialized
    if (!this.defaultCalendarId || this.isReady) {
      return
    }

    // Start with default calendar - it may be overridden later, but we need something to get going
    this.calendarId = this.defaultCalendarId

    // Load locale
    this.loadLocale(() => {
      // Once we have locale, we can start updating now-date
      this.now = DateTime.now().toUTC().setZone(this.locale.timezone)
      this.nowInterval = setInterval(() => {
        if (this.locale?.timezone) {
          this.now = DateTime.now().toUTC().setZone(this.locale.timezone)
          this.getCalendarView()?.updateTimes()
        }
      }, 1000 * 60)

      // Load resources, since we have calendar id
      this.loadServices(() => {
        this.loadResources(() => {
          this.loadPlaces(() => {
            this.recallNewBookingDefaults()
            // Finally, handle route-params (or defaults), and load data for time range
            // This could again change calendarId, and if so we'll reload resources
            this.onRouteChange()
          })
        })
      })
    })
  }

  public destroyed(): void {
    if (this.nowInterval) {
      clearInterval(this.nowInterval)
    }
  }

  private get calendars() {
    return vxm.booking?.calendars || []
  }

  private get defaultCalendarId(): number {
    return vxm.booking?.defaultCalendarId || 0
  }

  // ===========================================================================
  // Navigation
  // ===========================================================================

  private navigate(params: Record<string, unknown>): void {
    const route = {
      name: 'Booking/Calendar',
      query: {
        showWeekends: this.showWeekends ? '1' : '',
        focus: this.focus,
        type: this.type,
        calendarId: this.calendarId,
        startOfDay: this.startOfDayTime,
        endOfDay: this.endOfDayTime,
        interval: '' + this.intervalMinutes,
        areDayTimesAutomatic: this.areDayTimesAutomatic ? '1' : '',
      },
    }
    if (params.showWeekends !== undefined) {
      route.query.showWeekends = params.showWeekends ? '1' : ''
    }
    if (params.focus !== undefined) {
      route.query.focus = params.focus as string
    }
    if (params.type !== undefined) {
      route.query.type = params.type as string
    }
    if (params.calendarId !== undefined) {
      route.query.calendarId = params.calendarId as number
    }
    if (params.startOfDayTime !== undefined) {
      route.query.startOfDay = params.startOfDayTime as string
    }
    if (params.endOfDayTime !== undefined) {
      route.query.endOfDay = params.endOfDayTime as string
    }
    if (params.intervalMinutes !== undefined) {
      route.query.interval = '' + params.intervalMinutes
    }
    if (params.areDayTimesAutomatic !== undefined) {
      route.query.areDayTimesAutomatic = params.areDayTimesAutomatic ? '1' : ''
    }
    this.$router.push(route as unknown)
  }

  private reset(): void {
    const route = {
      name: 'Booking/Calendar',
    }
    this.$router.push(route)
  }

  @Watch('$route', { deep: true })
  private onRouteChange(): void {

    const query = this.$route.query || {}
    const before = this.getParamsAffectingBackendData()
    const defaults = this.getDefaults()
    if (query.showWeekends === undefined) {
      this.showWeekends = defaults.showWeekends
    } else {
      this.showWeekends = query.showWeekends === '1'
    }
    if (query.focus && DateTime.fromISO(query.focus + 'T00:00:00').isValid) {
      this.focus = query.focus as string
    } else {
      this.focus = defaults.focus
    }
    const calendarType = localStorage.getItem('calendarType')
    if (query.type && [this.TypeMonth, this.TypeWeek, this.TypeDay].indexOf(query.type as string) !== -1) {
      this.type = query.type as string
    } else if (calendarType !== null) {
      this.type = calendarType
    } else {
      this.type = defaults.type
    }
    const calendarIdBefore = this.calendarId
    if (query.calendarId && !isNaN(parseInt(query.calendarId as string))) {
      this.calendarId = parseInt(query.calendarId as string)
    } else {
      this.calendarId = defaults.calendarId
    }
    const didCalendarChange = this.calendarId !== calendarIdBefore
    if (query.startOfDay) {
      this.startOfDayTime = query.startOfDay as string
      this.saveSetting('startOfDayTime', this.startOfDayTime)
    } else {
      this.startOfDayTime = defaults.startOfDayTime
    }
    if (query.endOfDay) {
      this.endOfDayTime = query.endOfDay as string
      this.saveSetting('endOfDayTime', this.endOfDayTime)
    } else {
      this.endOfDayTime = defaults.endOfDayTime
    }
    if (query.interval && !isNaN(parseInt(query.interval as string))) {
      this.intervalMinutes = parseInt(query.interval as string)
      this.saveSetting('intervalMinutes', this.intervalMinutes)
    } else {
      this.intervalMinutes = defaults.intervalMinutes
    }
    if (query.areDayTimesAutomatic === undefined) {
      this.areDayTimesAutomatic = defaults.areDayTimesAutomatic
    } else {
      this.areDayTimesAutomatic = query.areDayTimesAutomatic === '1'
    }
    this.startOfDayTimeInput = this.startOfDayTime
    this.endOfDayTimeInput = this.endOfDayTime
    this.intervalMinutesInput = this.intervalMinutes

    let shouldLoad = false
    const after = this.getParamsAffectingBackendData()
    for (const key in after) {
      if (after[key] !== before[key]) {
        shouldLoad = true
        break
      }
    }
    if (shouldLoad) {
      if (didCalendarChange) {
        this.loadResources(() => {
          this.loadPlaces(() => {
            this.load()
          })
        })
      } else {
        this.load()
      }
    }
  }

  private getDefaults() {
    return {
      showWeekends: true,
      focus: this.today,
      type: this.TypeWeek,
      calendarId: this.defaultCalendarId,
      startOfDayTime: this.loadSetting('startOfDayTime') || '07:00',
      endOfDayTime: this.loadSetting('endOfDayTime') || '17:00',
      intervalMinutes: parseInt(this.loadSetting('intervalMinutes')) || 30,
      areDayTimesAutomatic: false,
    }
  }

  private saveSetting(key, val) {
    localStorage.setItem('eon-book-cal-' + key, '' + val)
  }

  private loadSetting(key): string {
    return localStorage.getItem('eon-book-cal-' + key) || ''
  }

  private getParamsAffectingBackendData(): Record<string, unknown> {
    return {
      focus: this.focus,
      type: this.type,
      calendarId: this.calendarId,
    }
  }

  private clickTypeMonth(): void {
    localStorage.setItem('calendarType', this.TypeMonth)
    this.navigate({ type: this.TypeMonth })
  }

  private clickTypeWeek(): void {
    localStorage.setItem('calendarType', this.TypeWeek)
    this.navigate({ type: this.TypeWeek })
  }

  private clickTypeDay(): void {
    localStorage.setItem('calendarType', this.TypeDay)
    this.navigate({ type: this.TypeDay })
  }

  private clickToday(): void {
    this.navigate({ focus: this.today })
  }

  private clickDay({ date }): void {
    if (this.isLoading || this.isSaving) {
      return
    }
    this.navigate({
      focus: date,
      type: this.TypeDay,
    })
  }

  private clickNext(): void {
    this.navigate({ focus: this.getPrevNextDate(true) })
  }

  private clickPrev(): void {
    this.navigate({ focus: this.getPrevNextDate(false) })
  }

  private getPrevNextDate(isNext: boolean): string {
    const now = this.getFocusAsDateTime()
    const duration = this.getDuration()
    const then = isNext ? now.plus(duration) : now.minus(duration)
    if (this.type === this.TypeMonth) {
      return then.toFormat('yyyy-MM') + '-01'
    } else {
      return this.formatDate(then)
    }
  }

  private getDuration(): Duration {
    switch (this.type) {
      case 'month':
        return Duration.fromISO('P1M')
      case 'week':
        return Duration.fromISO('P1W')
      case 'category':
        return Duration.fromISO('P1D')
      default:
        console.error('Invalid type: ' + this.type + ' - will now navigate next/prev')
        return null
    }
  }

  private getFocusAsDateTime(): DateTime {
    const focus = this.focus || this.today
    return DateTime.fromISO(focus + 'T00:00:00Z', { zone: this.locale.timezone })
  }

  private get today(): string {
    const dt = DateTime.now().toUTC().setZone(this.locale.timezone)
    return dt.toFormat('yyyy-MM-dd')
  }

  private formatDate(dt: DateTime): string {
    if (!dt) {
      return ''
    }
    return dt.toFormat('yyyy-MM-dd')
  }

  private getCalendarView() {
    if (!this.isReady || !this.$refs.calendar) {
      return null
    }
    return this.$refs.calendar as Vue & { updateTimes: () => void; timeToY: (t: unknown) => number }
  }

  private getCalendarModel() {
    if (!this.calendarId || !this.calendars) {
      return null
    }
    for (let i = 0; i < this.calendars.length; i++) {
      if (this.calendars[i].id === this.calendarId) {
        return this.calendars[i]
      }
    }
    return null
  }

  private get calendarIdInput(): number {
    return this.calendarId
  }

  private set calendarIdInput(value) {
    this.navigate({ calendarId: '' + value })
  }

  private get defaultPlace(): BookingPlace {
    if (!this.defaultPlaceId || !this.places) {
      return null
    }
    for (let i = 0; i < this.places.length; i++) {
      if (this.places[i].id === this.defaultPlaceId) {
        return this.places[i]
      }
    }
    return null
  }

  private get defaultResource(): BookingResource {
    if (!this.defaultResourceId || !this.resources) {
      return null
    }
    for (let i = 0; i < this.resources.length; i++) {
      if (this.resources[i].id === this.defaultResourceId) {
        return this.resources[i]
      }
    }
    return null
  }

  private get defaultService(): BookingService {
    if (!this.defaultServiceId || !this.services) {
      return null
    }
    for (let i = 0; i < this.services.length; i++) {
      if (this.services[i].id === this.defaultServiceId) {
        return this.services[i]
      }
    }
    return null
  }

  @Watch('defaultPlaceId')
  private onChangeDefaultPlaceId() {
    this.rememberNewBookingDefaults()
  }

  @Watch('defaultServiceId')
  private onChangeDefaultServiceId() {
    this.rememberNewBookingDefaults()
  }

  @Watch('defaultResourceId')
  private onChangeDefaultResourceId() {
    this.rememberNewBookingDefaults()
  }

  private rememberNewBookingDefaults() {
    localStorage.setItem(
      'eon-cal-defaults',
      JSON.stringify({
        defaultServiceId: this.defaultServiceId,
        defaultResourceId: this.defaultResourceId,
        defaultPlaceId: this.defaultPlaceId,
      }),
    )
  }

  private recallNewBookingDefaults() {
    const defaults = JSON.parse(localStorage.getItem('eon-cal-defaults') || '{}')
    this.defaultServiceId = defaults.defaultServiceId || null
    this.defaultResourceId = defaults.defaultResourceId || null
    this.defaultPlaceId = defaults.defaultPlaceId || null
  }

  private get showWeekendsInput(): boolean {
    return !!this.showWeekends
  }

  private set showWeekendsInput(value) {
    this.navigate({ showWeekends: value })
  }

  private get areDayTimesAutomaticInput(): boolean {
    return this.areDayTimesAutomatic
  }

  private set areDayTimesAutomaticInput(value) {
    this.navigate({ areDayTimesAutomatic: value })
  }

  private get primaryServicesForCalendar() {
    const result = []
    const calendar = this.getCalendarModel()
    for (let i = 0; i < this.services.length; i++) {
      const srv = this.services[i]
      if (srv.calendarId !== calendar.id) {
        continue
      }
      if (srv.isAddon) {
        continue
      }
      result.push(srv)
    }
    return result
  }

  // ===========================================================================
  // Getters
  // ===========================================================================

  private get isTypeDay() {
    return this.type === this.TypeDay
  }

  private get canSelectCalendar(): boolean {
    return this.calendars.length > 1
  }

  private get canSelectDefaultPlace(): boolean {
    const calendar = this.getCalendarModel()
    if (!calendar) {
      return false
    }
    let count = 0
    for (let i = 0; i < this.places.length; i++) {
      if (this.places[i].calendarId === this.calendarId) {
        count++
      }
    }
    return count > 0
  }

  private get canSelectDefaultResource(): boolean {
    const calendar = this.getCalendarModel()
    if (!calendar) {
      return false
    }
    let count = 0
    for (let i = 0; i < this.resources.length; i++) {
      if (this.resources[i].calendarId === this.calendarId) {
        count++
      }
    }
    return count > 0
  }

  private get canSelectDefaultService(): boolean {
    const calendar = this.getCalendarModel()
    if (!calendar) {
      return false
    }
    let count = 0
    for (let i = 0; i < this.services.length; i++) {
      if (this.services[i].calendarId === this.calendarId) {
        count++
      }
    }
    return count > 0
  }

  private get weekdays(): Array<number> {
    return this.showWeekends || !this.canHideWeekends ? [1, 2, 3, 4, 5, 6, 0] : [1, 2, 3, 4, 5]
  }

  private get canHideWeekends(): boolean {
    return this.type !== 'category'
  }

  private get dirtyCount(): number {
    let count = 0
    const seenEventIds = []
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].dirty) {
        count += 1
      }
      seenEventIds.push(this.events[i].getUniqueId())
    }
    for (let i = 0; i < this.stashedChanges.length; i++) {
      if (seenEventIds.indexOf(this.stashedChanges[i].getUniqueId()) === -1) {
        count += 1
      }
    }
    return count
  }

  private get dirtyCountText(): string {
    const count = this.dirtyCount
    if (count > 0) {
      return this.$t('%s unsaved changes').replace('%s', '' + count)
    }
    return ''
  }

  // ===========================================================================
  // Drag & drop + booking popup
  // ===========================================================================

  private dndStartDrag({ event, timed }): void {
    if (this.isLoading || this.isSaving) {
      return
    }
    // Started dragging existing event - so set dndDragEvent to indicate that
    if (event && timed) {
      this.dndDragEvent = event
      this.dndDragEvent.didMove = false
      this.dndDragEvent.wasCreatedNow = false
      this.dndDragTime = null
      this.dndExtendOriginal = null
    }
  }

  private dndStartTimeCategory(tms, nativeEvent): void {
    if (this.isLoading || this.isSaving) {
      return
    }
    // Used when type is "category" (aka day view), because here event contains category
    if (this.type === this.TypeDay) {
      nativeEvent.preventDefault()
      this.dndStartTimeReal(tms)
    }
  }

  private dndStartTime(tms, nativeEvent): void {
    if (this.isLoading || this.isSaving) {
      return
    }
    // Used when type is not category, where event does not contain category
    if (this.type !== this.TypeDay) {
      nativeEvent.preventDefault()
      this.dndStartTimeReal(tms)
    }
  }

  private dndStartTimeReal(tms): void {
    // Click or drag mouse across time, for existing or new event
    const mouse = this.dndCalendarDateToTimestamp(tms)

    if (this.dndDragEvent && this.dndDragTime === null) {
      // If we're dragging an existing event, remember starting time for dndMouseMove
      const start = this.dndDragEvent.start
      this.dndDragTime = mouse - start.getTime()
    } else {
      // If we're creating a new event, create it and remember start time for dndMouseMove
      // todo: possibly a v-select for default service and place, and set that already here, plus use the service of the duration then
      this.dndCreateStart = this.dndRoundTime(mouse)
      this.dndCreateEvent = new CalendarEvent({
        name: this.$t('c:calendar-new-event:New booking'),
        start: this.dndTimestampToJsDate(this.dndCreateStart),
        end: this.dndTimestampToJsDate(this.dndCreateStart + this.intervalMinutes * 60 * 1000),
        timed: true,
        wasCreatedNow: true,
        category: tms.category || '',
        newId: this.buildNewId(),
      })
      this.events.push(this.dndCreateEvent)
    }
  }

  private buildNewId(): string {
    return 'new-' + new Date().getTime() + '-' + Math.round(Math.random() * 1000000)
  }

  private dndExtendBottom(event): void {
    // Grab bottom of existing event to change its duration, remember things for dndMouseMove
    this.dndCreateEvent = event // Not really creating an event, we're extending an existing one
    this.dndCreateStart = event.start
    this.dndExtendOriginal = event.end
  }

  private dndMouseMoveCategory(tms): void {
    if (this.isLoading || this.isSaving) {
      return
    }
    // Used when type is "category" (aka day view), because here event contains category
    if (this.type === this.TypeDay) {
      this.dndMouseMoveReal(tms)
    }
  }

  private dndMouseMove(tms): void {
    if (this.isLoading || this.isSaving) {
      return
    }
    // Used when type is not category, where event does not contain category
    if (this.type !== this.TypeDay) {
      this.dndMouseMoveReal(tms)
    }
  }

  private dndMouseMoveReal(tms): void {
    if (this.dndDragEvent && this.dndDragTime !== null) {
      // We're moving an existing event around to a new start time
      const mouse = this.dndCalendarDateToTimestamp(tms)
      const start = this.dndDragEvent.start
      const end = this.dndDragEvent.end
      const duration = end.getTime() - start.getTime()
      const newStartTime = mouse - this.dndDragTime
      const newStart = this.dndRoundTime(newStartTime)
      const newEnd = newStart + duration

      if (newStart !== this.dndDragEvent.start.getTime()) {
        this.dndDragEvent.didMove = true
      }

      this.dndDragEvent.start = this.dndTimestampToJsDate(newStart)
      this.dndDragEvent.end = this.dndTimestampToJsDate(newEnd)
      if (tms.category && this.dndDragEvent.category !== tms.category) {
        this.dndDragEvent.category = tms.category
        this.dndDragEvent.didMove = true
      }
    } else if (this.dndCreateEvent && this.dndCreateStart !== null) {
      // We're dragging bottom of new or existing event to change its duration
      const mouse = this.dndCalendarDateToTimestamp(tms)
      const mouseRounded = this.dndRoundTime(mouse, false)

      if (mouseRounded > this.dndCreateStart) {
        if (mouseRounded !== this.dndCreateEvent.end.getTime()) {
          this.dndCreateEvent.didMove = true
          this.dndCreateEvent.end = this.dndTimestampToJsDate(mouseRounded)
        }
      }

      /*
      const min = Math.min(mouseRounded, this.dndCreateStart)
      const max = Math.max(mouseRounded, this.dndCreateStart)

      if (min !== this.dndCreateEvent.start.getTime() || max !== this.dndCreateEvent.end.getTime()) {
        this.dndCreateEvent.didMove = true
      }

      this.dndCreateEvent.start = this.dndTimestampToJsDate(min)
      this.dndCreateEvent.end = this.dndTimestampToJsDate(max)
      */
    }
  }

  private dndCancelDrag(): void {
    if (this.isLoading || this.isSaving) {
      return
    }
    // If we drag outside screen, the operation is cancelled
    // todo: needs work to properly revert in all cases?
    if (this.dndCreateEvent) {
      if (this.dndExtendOriginal) {
        this.dndCreateEvent.end = this.dndExtendOriginal
      } else {
        const i = this.events.indexOf(this.dndCreateEvent)
        if (i !== -1) {
          this.events.splice(i, 1)
        }
      }
    }

    this.dndCreateEvent = null
    this.dndCreateStart = null
    this.dndDragTime = null
    this.dndDragEvent = null
  }

  private dndEndDrag(): void {
    if (this.isLoading || this.isSaving) {
      return
    }
    // Every completed drag/create operation ends here
    // NB: We'll also handle "click on booking" here, since the standard @click:event on v-calendar will conflict with this otherwise
    let ev = null
    if (this.dndCreateEvent) {
      // Created new booking, or extended duration of one
      if (this.dndCreateEvent.start?.getTime() === this.dndCreateEvent.end?.getTime()) {
        // If changed to zero duration, then revert
        const e = bookingToEvent(this.dndCreateEvent.booking)
        this.dndCreateEvent.update(e)
      }
      this.dndCreateEvent.onDragEnd()
      ev = this.dndCreateEvent
    } else if (this.dndDragEvent) {
      // Moved existing booking
      this.dndDragEvent.onDragEnd()
      ev = this.dndDragEvent
    }
    this.dndDragTime = null
    this.dndDragEvent = null
    this.dndCreateEvent = null
    this.dndCreateStart = null
    this.dndExtendOriginal = null

    // Booking popup
    if (ev && (!ev.didMove || ev.wasCreatedNow)) {
      let booking
      let draft = null
      if (ev.booking) {
        // Existing - or "new" but previously submitted dialog and now editing that again
        booking = new Booking(ev.booking)
        if (ev.draft) {
          draft = new BookingDraft(ev.draft)
          draft.resource = this.getResourceByCategoryName(ev.category)
          draft.time = ev.getStartTimeAsString()
          draft.duration = ev.getDuration()
        }
      } else {
        // New
        booking = new Booking()
        draft = new BookingDraft()
        draft.resource = this.isTypeDay ? this.getResourceByCategoryName(ev.category) : this.defaultResource
        draft.time = ev.getStartTimeAsString()
        draft.duration = '' // ev.getDuration() - leave this blank so that it auto-updates for services when new booking is blank
        draft.place = this.defaultPlace
        draft.primaryService = this.defaultService
      }
      this.activeBooking = booking
      this.activeBookingDraft = draft
      this.activeBookingDialogKey++
      this.activeBookingDialogVisible = true
      this.activeEvent = ev
    }
  }

  private getResourceByCategoryName(name: string): BookingResource {
    if (name) {
      for (const resource of this.resources) {
        if (resource.name === name) {
          return resource
        }
      }
    }
    return null
  }

  private dndCalendarDateToTimestamp(tms): number {
    return new Date(tms.year, tms.month - 1, tms.day, tms.hour, tms.minute).getTime()
  }

  private dndTimestampToJsDate(ts): Date {
    return new Date(ts)
  }

  private dndRoundTime(time, down = true): number {
    const roundTo = this.intervalMinutes // 15 // minutes
    const roundDownTime = roundTo * 60 * 1000

    return down ? time - (time % roundDownTime) : time + (roundDownTime - (time % roundDownTime))
  }

  // ===========================================================================
  // Load data
  // ===========================================================================

  private reload(): void {
    this.load()
  }

  private load(): void {
    this.isLoading = true

    // If we move to another period, and have unsaved changes in the old period,
    // stash those changes so that we can restore them if we come back to the old period
    this.stashChanges()

    const fromDate = this.getStartOfPeriod()
    const toDate = this.getEndOfPeriod()
    const params = 'perPage=1000&start=' + encodeURIComponent(fromDate) + '&end=' + encodeURIComponent(toDate)
    const dtToTime = (dt: Date): string => {
      if (!dt) {
        return ''
      }
      const h = dt.getHours() < 10 ? '0' + dt.getHours() : '' + dt.getHours()
      const m = dt.getMinutes() < 10 ? '0' + dt.getMinutes() : '' + dt.getMinutes()
      return h + ':' + m
    }
    this.$axios
      .get('/v4/site/calendars/' + this.calendarId + '/bookings?' + params)
      .then((response) => {
        if (response.data.meta.lastPage > 1) {
          console.error('Got paginated! : ' + JSON.stringify(response.data.meta))
        }
        this.events = []
        let minTime = ''
        let maxTime = ''
        for (let i = 0; i < response.data.data.length; i++) {
          const b = new Booking(response.data.data[i])
          const ev = bookingToEvent(b)
          this.events.push(ev)
          const startTime = dtToTime(ev.start)
          const endTime = dtToTime(ev.end)
          if (minTime === '' || startTime < minTime) {
            minTime = startTime
          }
          if (maxTime === '' || endTime > maxTime) {
            maxTime = endTime
          }
        }
        this.startOfDayTimeFromEvents = minTime || '10:00'
        this.endOfDayTimeFromEvents = maxTime || '14:00'
        this.applyStashedChanges()
        this.isLoading = false
        this.isReady = true
      })
      .catch((err) => {
        this.events = []
        vxm.alert.onAxiosError(err, 'Failed to load bookings')
        this.isLoading = false
        this.isReady = true
      })
  }

  private getStartOfPeriod(): string {
    let now = this.getFocusAsDateTime()
    switch (this.type) {
      case this.TypeMonth:
        return now.toFormat('yyyy-MM') + '-01'
      case this.TypeWeek:
        if (now.weekday !== 1) {
          for (let i = now.weekday; i > 1; i--) {
            now = now.minus(Duration.fromISO('P1D'))
          }
        }
        return this.formatDate(now)
      case this.TypeDay:
        return this.formatDate(now)
      default:
        return ''
    }
  }

  private getEndOfPeriod(): string {
    let now = this.getFocusAsDateTime()
    switch (this.type) {
      case this.TypeMonth:
        now = now.plus(Duration.fromISO('P1M'))
        now = DateTime.fromISO(now.toFormat('yyyy-MM') + '01T00:00:00', { zone: this.locale.timezone })
        now = now.minus(Duration.fromISO('P1D'))
        return this.formatDate(now)
      case this.TypeWeek:
        if (now.weekday !== 0) {
          for (let i = now.weekday; i < 7; i++) {
            now = now.plus(Duration.fromISO('P1D'))
          }
        }
        return this.formatDate(now)
      case this.TypeDay:
        return this.formatDate(now)
      default:
        return ''
    }
  }

  // ===========================================================================
  // Load portal settings
  // ===========================================================================

  private loadLocale(callback = null): void {
    this.$axios
      .get('/v4/site/locale')
      .then((response) => {
        this.locale = response.data.data
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to load locale')
      })
  }

  private loadServices(callback) {
    const url = '/v4/site/calendars/' + this.calendarId + '/services?perPage=1000'
    this.$axios
      .get(url)
      .then((response) => {
        const services = []
        for (let i = 0; i < response.data.data.length; i++) {
          services.push(new BookingService(response.data.data[i]))
        }
        services.sort((a: BookingService, b: BookingService) => {
          const res = a.name.localeCompare(b.name)
          if (res === 0) {
            return a.id > b.id ? 1 : -1
          }
          return res
        })
        this.services = services
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to load services')
      })
  }

  private loadResources(callback) {
    const url = '/v4/site/calendars/' + this.calendarId + '/resources?perPage=1000'
    this.$axios
      .get(url)
      .then((response) => {
        const resources = []
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].isDropinDefault) {
            continue
          }
          if (response.data.data[i].isHidden) {
            continue
          }
          resources.push(new BookingResource(response.data.data[i]))
        }
        resources.sort((a: BookingResource, b: BookingResource) => {
          const res = a.name.localeCompare(b.name)
          if (res === 0) {
            return a.id > b.id ? 1 : -1
          }
          return res
        })
        this.resources = resources
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to load resources')
      })
  }

  private loadPlaces(callback) {
    const url = '/v4/site/calendars/' + this.calendarId + '/places?perPage=1000'
    this.$axios
      .get(url)
      .then((response) => {
        const places = []
        for (let i = 0; i < response.data.data.length; i++) {
          if (response.data.data[i].isDropinDefault) {
            continue
          }
          places.push(new BookingPlace(response.data.data[i]))
        }
        places.sort((a: BookingPlace, b: BookingPlace) => {
          const res = a.name.localeCompare(b.name)
          if (res === 0) {
            return a.id > b.id ? 1 : -1
          }
          return res
        })
        this.places = places
        if (callback) {
          callback()
        }
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to load places')
      })
  }

  private get categories(): Array<string> {
    const categories = []
    for (let i = 0; i < this.resources.length; i++) {
      categories.push(this.resources[i].name)
    }
    return categories
  }

  // ===========================================================================
  // Calendar display customization / localization
  // ===========================================================================

  private get localeCode(): string {
    return this.locale?.language ? this.locale.language.split('_')[0] : 'en'
  }

  private formatDay(v) {
    if (this.type === this.TypeMonth) {
      return v.day
    }
    return v.day + ' ' + this.monthToShortName(v.month)
  }

  private monthToShortName(month: number): string {
    const months = ['', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    return this.$t('c:month:' + months[month])
  }

  private get intervalCount(): number {
    const dt = DateTime.now().toUTC().setZone(this.locale.timezone)
    const start = DateTime.fromISO(dt.toFormat('yyyy-MM-dd') + 'T' + this.startOfDayTimeReal + ':00', {
      zone: this.locale.timezone,
    })
    const end = DateTime.fromISO(dt.toFormat('yyyy-MM-dd') + 'T' + this.endOfDayTimeReal + ':00', {
      zone: this.locale.timezone,
    })
    const intervalSeconds = this.intervalMinutes * 60
    const lengthSeconds = end.diff(start).milliseconds / 1000
    return Math.ceil(lengthSeconds / intervalSeconds)
  }

  private onCalendarPropsUpdate(): void {
    this.navigate({
      startOfDayTime: this.startOfDayTimeInput,
      endOfDayTime: this.endOfDayTimeInput,
      intervalMinutes: this.intervalMinutesInput,
    })
  }

  private get startOfDayTimeReal(): string {
    return this.areDayTimesAutomatic ? this.startOfDayTimeFromEvents : this.startOfDayTime
  }

  private get endOfDayTimeReal(): string {
    return this.areDayTimesAutomatic ? this.endOfDayTimeFromEvents : this.endOfDayTime
  }

  // ===========================================================================
  // New
  // ===========================================================================

  private onSubmitNew(data) {
    const evt = draftToEvent(data.draft)
    this.activeEvent.update(evt)
    this.activeEvent.booking = data.booking
    this.activeEvent.draft = data.draft
  }

  private onAbortNew() {
    // If it has a booking, it means at some time the newly added booking was updated with info,
    // even if not saved yet. Hence it should be kept.
    if (this.activeEvent.booking) {
      return
    }
    // If not, then we just opened the new booking window and closed it again, hence
    // it should be removed from the calendar.
    this.discardActiveEvent()
  }

  private onDiscardNew() {
    // Above we handle removing events on close window,
    // but we may also choose to actively discard an event that has info (but is not saved),
    // that happens here
    this.discardActiveEvent()
  }

  private discardActiveEvent() {
    const i = this.events.indexOf(this.activeEvent)
    if (i !== -1) {
      this.events.splice(i, 1)
    }
  }

  // ===========================================================================
  // Save
  // ===========================================================================

  private discardChanges() {
    if (this.isLoading || this.isSaving) {
      return
    }
    const msg = this.$t('All unsaved changes will be discarded. Are you sure?')
    if (!confirm(msg)) {
      return
    }
    this.stashedChanges = []
    this.events = []
    this.load()
  }

  private saveChanges() {
    // Combine dirty events and stashed changes into a list of events to save

    const seenEventIds = []
    const events = []
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].dirty) {
        events.push(this.events[i])
      }
      seenEventIds.push(this.events[i].getUniqueId())
    }
    for (let i = 0; i < this.stashedChanges.length; i++) {
      if (seenEventIds.indexOf(this.stashedChanges[i].getUniqueId()) === -1) {
        events.push(this.stashedChanges[i])
      }
    }

    // Check that everything to be saved is complete (should already be covered but better save than sorry)

    for (let i = 0; i < events.length; i++) {
      if (!events[i].booking) {
        alert(
          this.$t(
            'Some bookings are incomplete and cannot be saved. Please attend to the bookings with orange color and try again.',
          ),
        )
        return
      }
    }

    // Are you sure?

    const msg = this.$t(
      'Changes will be saved. New bookings and changed times will trigger notifications to customers (but change of resource and duration will not). Proceed?',
    )
    if (!confirm(msg)) {
      return
    }

    this.isSaving = true

    // Build payload to backend

    const updates = []
    const updateToEventIndex = {}

    for (let i = 0; i < events.length; i++) {
      // Skip events that do not have changes

      if (!events[i].dirty) {
        continue
      }

      if (events[i].booking?.id) {
        // Update time/duration/resource for existing booking

        updates.push({
          bookingId: events[i].booking.id,
          time: events[i].start,
          duration: events[i].getDuration(),
          serviceId: events[i].booking.primaryService.id,
          resourceId: this.getResourceByCategoryName(events[i].category).id,
          placeId: events[i].booking.primaryPlaceId,
          addonServiceIds: events[i].booking.addonServiceIds,
        })
      } else {
        // Add a new booking

        const draft = events[i].draft
        const addonServiceIds = []
        for (const s of draft.addonServices) {
          addonServiceIds.push(s.id)
        }
        let referenceType = ''
        let referenceId = null
        let wheelChangeSeason = ''
        if (draft.tyreHotelId && draft.tyreHotelSeason) {
          referenceType = 'tyre-hotel'
          referenceId = draft.tyreHotelId
          wheelChangeSeason = draft.tyreHotelSeason
        } else if (draft.orderId) {
          referenceType = 'order'
          referenceId = draft.orderId
        } else if (draft.carId) {
          referenceType = 'car'
          referenceId = draft.carId
        } else if (draft.customerId) {
          referenceType = 'customer'
          referenceId = draft.customerId
        }

        updates.push({
          time: events[i].start, // is Date() will become string with timezone, will be handled by micropop to become UTC
          duration: events[i].getDuration(),
          resourceId: this.getResourceByCategoryName(events[i].category).id,
          placeId: draft.place ? draft.place.id : null,
          contactName: draft.contactName,
          contactMobile: draft.contactMobile,
          contactEmail: draft.contactEmail,
          comment: draft.comment,
          customerId: draft.customerId,
          carId: draft.carId,
          carLicenseplate: draft.carLicenseplate,
          serviceId: draft.primaryService.id,
          addonServiceIds: addonServiceIds,
          referenceType: referenceType,
          referenceId: referenceId,
          wheelChangeSeason: wheelChangeSeason || null,
        })
      }

      // Keep track of which backend-update corresponds to which CalendarEvent
      updateToEventIndex[updates.length - 1] = i
    }

    // Send updates to backend

    this.$axios
      .post('/v4/site/calendars/' + this.calendarId + '/bookings/save-many', { bookings: updates })
      .then((_response) => {
        /* After stashing, though possible, it's complicated to dynamically update - so instead we just reload
        for (let i = 0; i < response.data.data.length; i++) {
          const booking = response.data.data[i]
          const event = this.events[updateToEventIndex[i]]
          event.booking.update(booking)
          event.draft = null
          event.checkDirty()
        }
        */
        this.stashedChanges = []
        this.events = []
        this.load()
        this.isSaving = false
      })
      .catch((err) => {
        console.error('Calendar.save.error:', err) // todo: Why does not onAxiosError below get dispatched properly?
        vxm.alert.onAxiosError(err, 'Error saving bookings')
        this.isSaving = false
      })
  }

  // ===========================================================================
  // Stash
  // ===========================================================================

  /**
   * When we leave one period (f.ex. a week) and enter another,
   * we may have changes in the period we're leaving.
   *
   * Those will be lost because we load() and keep only bookings for the period in this.events
   * (since holding all bookings in memory is obviously too much).
   *
   * We therefore "stash" unsaved changes for the old period, before loading data for the new, so that
   * - (a) we can restore them if returning to the old period later
   * - (b) we can save them even if we're no longer in the old period
   *
   * This of this one as "git stash" and applyStashedChanges() as "git stash apply" :)
   */
  private stashChanges() {
    for (let i = 0; i < this.events.length; i++) {
      const incoming = this.events[i]
      // Stash bookings that are not saved
      // - Added bookings not created yet (!incoming.booking)
      // - Bookings that exists but are changed (incoming.dirty)
      if (!incoming.booking || incoming.dirty) {
        // Check if already exists in stash
        let existingStash = null
        for (let j = 0; j < this.stashedChanges.length; j++) {
          const stashed = this.stashedChanges[j]
          if (stashed.getUniqueId() === incoming.getUniqueId()) {
            existingStash = stashed
            break
          }
        }
        // Add to stash, or update if exists (because could have been moved yet again after first stash)
        if (existingStash) {
          existingStash.update(incoming)
        } else {
          this.stashedChanges.push(this.events[i])
        }
      }
    }
  }

  /**
   * See comment on stashChanges().
   * After we've stashed changes for the old period, and loaded events for the new period,
   * we apply stashed changes to the new period.
   */
  private applyStashedChanges() {
    const fromDate = this.getStartOfPeriod()
    const toDate = this.getEndOfPeriod()
    const removeEvents = []
    for (let i = 0; i < this.stashedChanges.length; i++) {
      const ev = this.stashedChanges[i]
      if (this.isEventBetweenDates(ev, fromDate, toDate)) {
        let foundExisting = false
        if (ev.booking?.id) {
          const eventInView = this.findEvent(ev)
          if (eventInView) {
            // Stashed change is within the visible period, and refers to a booking in this.events,
            // so we can simply update the existing event's data within this period view.
            eventInView.update(ev)
            eventInView.checkDirty()
            foundExisting = true
          }
        }
        if (!foundExisting) {
          // Stashed change is in this period, but does not refer to a booking in this.events.
          // Could be for two reasons:
          // a) It's a new unsaved booking, not yet in DB and therefore not loaded with the new period
          // b) It's an existing booking, but it exists in another period than the one we're inside now
          // In both cases we simply want to add it to the current period view.
          this.events.push(ev)
        }
      } else {
        // Stashed change is in this.events, but is not in the visible period, which
        // means it has been moved outside this period but that change is not saved,
        // and therefore it should be removed from this view (executed further down)
        const eventInView = this.findEvent(ev)
        if (eventInView) {
          removeEvents.push(eventInView)
        }
      }
    }
    // Execute removing events
    if (removeEvents) {
      while (removeEvents.length > 0) {
        const i = this.events.indexOf(removeEvents.pop())
        if (i !== -1) {
          this.events.splice(i, 1)
        }
      }
    }
  }

  private isEventBetweenDates(event: CalendarEvent, fromDate: string, toDate: string): boolean {
    const start = this.formatDate(DateTime.fromJSDate(event.start))
    const end = this.formatDate(DateTime.fromJSDate(event.end))
    return start >= fromDate && start <= toDate && end >= fromDate && end <= toDate
  }

  private findEvent(ev: CalendarEvent): CalendarEvent {
    for (let i = 0; i < this.events.length; i++) {
      if (this.events[i].getUniqueId() === ev.getUniqueId()) {
        return this.events[i]
      }
    }
    return null
  }
}
